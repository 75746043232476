import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import generalCss from '../styles/generalCss'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        // paddingTop: "30px",
        // [theme.breakpoints.up('md')]: {
        //     paddingTop: "50px"
        // }
    },
    colored: {
        color: theme.palette.text.secondary
    },
    title: {
        paddingBottom: "0px",
        // [theme.breakpoints.up("md")]: {
        //     paddingBottom: "40px"
        // }
    },
    childrenRoot: {
        padding: "20px 0px",
        [theme.breakpoints.up('md')]: {
            padding: "50px 0px 30px"
        }
    },
    children: {
        padding: "30px 20px 0px",
        [theme.breakpoints.up('md')]: {
            padding: "50px 2rem 0px"
        }
    }
}))

export default function SectionTitleComponent({
    sectionId = 'section', title = '', titleVariant = 'h2', coloredTitle, reverse = false,
    subTitle = '', children, backgroundColor = '', titleAlign = 'center', containerStyles = {}
}) {
    const classes = useStyles()
    return (
        <section id={sectionId} style={{ width: "100%" }}>
            <div className={classes.root} style={{ backgroundColor: !!backgroundColor ? backgroundColor : 'white' }}>
                <div style={{ maxWidth: generalCss.appMaxWidth, width: '100%', ...containerStyles }} className={classes.childrenRoot}>
                    <Grid container>
                        {
                            !!title &&
                            <Grid item xs={12}>
                                <div className={classes.title}>
                                    <Grid container direction='column' alignItems='center'>
                                        <Grid item >
                                            {
                                                reverse ?
                                                    <Typography variant={titleVariant} style={{ textAlign: titleAlign, }} color='textPrimary' >
                                                        <span className={classes.colored}>{title}</span>{" "}{coloredTitle}
                                                    </Typography>
                                                    :
                                                    <Typography variant={titleVariant} style={{ textAlign: titleAlign, }} color='textPrimary' >
                                                        {title}{" "}<span className={classes.colored}>{coloredTitle}</span>
                                                    </Typography>
                                            }

                                        </Grid>
                                        {
                                            !!subTitle &&
                                            <Grid item >
                                                <Typography variant={'body1'} color='textPrimary' style={{ textAlign: 'center', maxWidth: "700px" }} >{subTitle}</Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                </div>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <div className={classes.children}>
                                {children}
                            </div>

                        </Grid>
                    </Grid>
                </div>
            </div>
        </section>

    )
}
