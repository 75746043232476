import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
const useStyles = makeStyles(theme => ({
    root: {
        padding: "5px",
        paddingLeft: '0px',
        textTransform: 'none',
        color: theme.palette.text.secondary,
        fontSize: '1rem',
        lineHeight: "1.25rem",
        fontWeight: "600",
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}))

export default function LinkButton({ children, className, ...props }) {
    const classes = useStyles()
    return (
        <Button variant='text' className={clsx(classes.root, className)} fullWidth={false}  {...props}>
            {children}
        </Button>
    )
}
