import React from 'react'
import FooterList from './FooterList'
import FooterHeader from './FooterHeader'
import { makeStyles } from '@material-ui/core'
import generalCss from '../../styles/generalCss'
import { graphql, useStaticQuery } from 'gatsby'
import Routes from '../../utilities/Routes'
import { socialLinks } from '../../utilities/Configurations'
import ContactForm from '../Contact/Form'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // maxWidth: generalCss.appMaxWidth,
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            paddingTop: "30px"
        }
    },
    mainRoot: {
        padding: "30px 0px 0px",
        [theme.breakpoints.up('sm')]: {
            padding: "0px"
        }
    }
}))

export default function Footer({ showContact = true }) {
    const classes = useStyles()
    const cmsData = useStaticQuery(graphql`
    query FooterQuery {
        site{
            siteMetadata{
                siteVersion
                siteEnv
            }
        }
        cms {
          brsCompanyLocations(where: {showInFooter:true}) {
            address
            contactEmail
            contactPhone1
            contactPhone2
            googleEmbedURL
            showInFooter
          }
          brsSolutions {
            id
            slug
            title
            showDetailPage
          }
          brsProducts {
            id
            slug
            title
            showDetailPage
          }
        }
      }
    `)
    const footerHeaderDataList = cmsData.cms.brsCompanyLocations
    const footerHeaderData = footerHeaderDataList.length !== 0 ? footerHeaderDataList[0] : {}
    const footerDisclaimer = '© 2021 Black Rook Consulting. All Rights Reserved.'
    const appVersionData = cmsData.site.siteMetadata
    const solutionList = cmsData.cms.brsSolutions.map(item => ({
        ...item,
        slug: !!item.showDetailPage && !!item.slug ? `${Routes.solutions}/${item.slug}` : `${Routes.home}#${item.slug}`
    }))
    const productList = cmsData.cms.brsProducts.map(item => ({
        ...item,
        slug: !!item.showDetailPage && !!item.slug ? `${Routes.products}/${item.slug}` : `${Routes.home}#${item.slug}`
    }))
    const pageList = [
        { id: 'about', title: "About Us", slug: Routes.about },
        { id: "contact", title: "Contact", slug: Routes.contact },
        { id: "getQuote", title: "Get a Quote", slug: "" },
        { id: 'portfolio', title: "Portfolio", slug: Routes.portfolio }
    ]
    const otherLinks = [
        { id: 'terms', title: "Terms of Use", slug: '' },
        { id: "privacy-policy", title: "Privacy Policy", slug: '' },
        { id: "sitemap", title: "Sitemap", slug: "" },
    ]
    const footerColumnList = [
        { id: 'solution', list: solutionList, xsWidth: 12, smWidth: 4 },
        { id: 'products', list: productList, xsWidth: 12, smWidth: 3 },
        { id: "others", list: pageList, xsWidth: 12, smWidth: 2 },
        { id: 'social', list: socialLinks, xsWidth: 8, smWidth: 5, mdWidth: 2 }
    ]
    return (
        <div className={classes.mainRoot}>
            {
                showContact &&
                <ContactForm />
            }
            <div className={classes.root}>
                <FooterHeader data={footerHeaderData} />
                <FooterList
                    footerColumnList={footerColumnList}
                    footerRowList={otherLinks}
                    data={footerHeaderData}
                    disclaimer={footerDisclaimer} appVersionData={appVersionData} />
            </div>
        </div>
    )
}
