import React from 'react'
import { ThemeProvider, Toolbar, makeStyles } from '@material-ui/core'
import Theme from '../gatsby-theme-material-ui-top-layout/theme'
import Navbar from './Navbar'
import '../styles/globalCss.css'
import Footer from './Footer'
import Seo from './Seo'
import { seoValuesReturn } from '../utilities/helperFunctions'
import DrawerComponent from './DrawerComponent'
import { useStaticQuery, graphql } from 'gatsby'
import Routes from '../utilities/Routes'

const useStyles = makeStyles(theme => ({
    toolbar: {
        minHeight: "80px",
        [theme.breakpoints.up('md')]: {
            minHeight: '139px'
        }
    }
}))


export default function Layout({ pageSeo = { title: '', description: "", schemaTag: [] }, showContact = true, ...props }) {
    const classes = useStyles()
    const seoValues = seoValuesReturn(pageSeo)
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const appBarReturnData = useStaticQuery(graphql`
    query AppBarQuery {
        cms {
          brsCaseStudies(limit: 4) {
            id
            slug
            title
          }
          brsProducts {
            id
            title
            slug
            showDetailPage
          }
          brsSolutions {
            id
            showDetailPage
            slug
            title
          }
          brsCompanyLocations(where: {showInFooter:true}) {
            contactEmail
            contactPhone1
            contactPhone2
            id
          }
        }
      }
    `)
    const contactData = {
        contactEmail: appBarReturnData.cms.brsCompanyLocations.length !== 0 ? appBarReturnData.cms.brsCompanyLocations[0].contactEmail : '',
        contactPhone1: appBarReturnData.cms.brsCompanyLocations.length !== 0 ? appBarReturnData.cms.brsCompanyLocations[0].contactPhone1 : '',
        contactPhone2: appBarReturnData.cms.brsCompanyLocations.length !== 0 ? appBarReturnData.cms.brsCompanyLocations[0].contactPhone2 : '',
    }
    const navigationList = [
        {
            id: "home", title: "Home", titleRoute: Routes.home, showInDrawer: true,
            showAsAccordion: false, showInAppBar: true, showInMenu: false, list: [],
            showButton: false, buttonTitle: '', menuWidth: 12,
            buttonRoute: Routes.home
        },
        {
            id: "about", title: "About", titleRoute: Routes.about, showInDrawer: true,
            showAsAccordion: false, showInAppBar: true, showInMenu: false, list: [],
            showButton: false, buttonTitle: '', menuWidth: 12,
            buttonRoute: Routes.about
        },
        {
            id: "solutions", title: "Solutions", titleRoute: Routes.solutions,
            showAsAccordion: true, showInAppBar: true, showInMenu: true, showInDrawer: true,
            list: appBarReturnData.cms.brsSolutions.map(item => {
                return { ...item, slug: item.showDetailPage ? `${Routes.solutions}/${item.slug}` : `${Routes.home}#${item.slug}` }
            }),
            showButton: false, buttonTitle: 'View More Solutions', menuWidth: 3,
            buttonRoute: Routes.solutions
        },
        {
            id: "portfolio", title: "Portfolio", titleRoute: Routes.portfolio, showInDrawer: true,
            showAsAccordion: false, showInAppBar: true, showInMenu: false, list: [],
            showButton: false, buttonTitle: '', menuWidth: 12,
            buttonRoute: Routes.portfolio
        },
        {
            id: "products", title: 'Products', titleRoute: Routes.products, showInDrawer: true,
            showAsAccordion: true, showInAppBar: false, showInMenu: true,
            list: appBarReturnData.cms.brsProducts.map(item => {
                return { ...item, slug: item.showDetailPage ? `${Routes.products}/${item.slug}` : `${Routes.home}#${item.slug}` }
            }), showButton: false, buttonTitle: 'View More Products', menuWidth: 3,
            buttonRoute: Routes.products
        },
        {
            id: "case-studies", title: 'Case Studies', titleRoute: Routes.caseStudies, showInDrawer: true,
            showAsAccordion: true, showInAppBar: true, showInMenu: true,
            list: appBarReturnData.cms.brsCaseStudies.map(item => {
                return { ...item, slug: `${Routes.caseStudies}/${item.slug}` }
            }).slice(0, 3),
            showButton: appBarReturnData.cms.brsCaseStudies.length > 3, buttonTitle: 'View More Case Studies', menuWidth: 5,
            buttonRoute: Routes.caseStudies
        }
    ]

    return (
        <ThemeProvider theme={Theme}>
            <Seo {...seoValues} />
            <Navbar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}
                appBarData={navigationList}
                contactData={contactData}
            />
            <Toolbar className={classes.toolbar} />
            <DrawerComponent
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                appBarData={navigationList}
                contactData={contactData}
            />
            <main>
                <div className='mainContainerWrapper'>
                    {props.children}
                </div>
            </main>
            <Footer showContact={showContact} />
        </ThemeProvider>
    )
}
