import React from 'react'

export default function Twitter() {
    return (
        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.483 1.42039C13.9506 1.66155 13.3775 1.82397 12.7765 1.8973C13.3901 1.52227 13.8612 0.928716 14.0828 0.221473C13.5093 0.568943 12.8726 0.820441 12.1963 0.956771C11.6542 0.368141 10.8813 0 10.0274 0C8.38655 0 7.05613 1.35641 7.05613 3.02928C7.05613 3.26651 7.08268 3.49783 7.13337 3.71979C4.6637 3.5938 2.4745 2.3875 1.00892 0.554671C0.753066 1.00205 0.606798 1.52227 0.606798 2.07744C0.606798 3.12821 1.13056 4.05545 1.92852 4.59881C1.44096 4.58306 0.983329 4.44672 0.582177 4.21984V4.25773C0.582177 5.72586 1.60702 6.94988 2.96544 7.22894C2.71635 7.29784 2.45374 7.33525 2.18293 7.33525C1.99128 7.33525 1.80494 7.31605 1.62344 7.28062C2.00142 8.48396 3.09916 9.36002 4.39916 9.38463C3.38252 10.1967 2.10134 10.6815 0.709136 10.6815C0.468734 10.6815 0.232195 10.6672 0 10.6387C1.31497 11.498 2.87662 12 4.5546 12C10.0196 12 13.0083 7.38397 13.0083 3.38118C13.0083 3.24977 13.0058 3.11886 12.9996 2.98942C13.5808 2.56124 14.0848 2.02773 14.483 1.42039Z" fill="white" />
        </svg>

    )
}
