import React from 'react'

export default function Logo({ width = '126', height = '55' }) {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5617 40.5332L6.90967 47.2915L13.5617 54.0499L20.2137 47.2915L13.5617 40.5332Z" fill="#0093EA" />
            <path d="M20.2152 20.2573H6.91162V34.937H20.2152V20.2573Z" fill="#1D2F43" />
            <path d="M0.258789 53.9785V40.5317H13.5623L0.258789 53.9785Z" fill="#0093EA" />
            <path d="M26.8675 53.9785V40.5317H13.562L26.8675 53.9785Z" fill="#0093EA" />
            <path d="M13.5617 13.4995L6.90967 20.2578L13.5617 27.0162L20.2137 20.2578L13.5617 13.4995Z" fill="#263B50" />
            <path d="M13.5627 27.0165L6.91064 33.7749L13.5627 40.5333L20.2147 33.7749L13.5627 27.0165Z" fill="#2A6AE2" />
            <path d="M6.91033 33.7734L0.258301 40.5317L6.91033 47.2901L13.5624 40.5317L6.91033 33.7734Z" fill="#0D80E8" />
            <path d="M20.214 33.7734L13.562 40.5317L20.214 47.2901L26.8661 40.5317L20.214 33.7734Z" fill="#0D80E8" />
            <path d="M13.555 13.5167L20.207 6.7583L13.555 -5.18857e-05L6.90297 6.7583L13.555 13.5167Z" fill="#526373" />
            <path d="M13.5643 13.4985L6.90958 20.2596L0.256836 13.4985L6.90372 6.75928L13.5643 13.4985Z" fill="#364353" />
            <path d="M26.8686 0.0527344V13.4975L20.2139 6.75829L26.8686 0.0527344Z" fill="#526373" />
            <path d="M26.8685 13.4985L20.2177 20.2596L13.5649 13.4985L20.2138 6.75928L26.8685 13.4985Z" fill="#364353" />
            <path d="M6.90372 6.75683L0.256836 13.4961V0.0512695L6.90372 6.75683Z" fill="#526373" />
            <path d="M61.1799 16.8847C61.6587 17.5216 61.8998 18.3055 61.8998 19.2363C61.8998 20.5556 61.3969 21.826 60.3877 22.5364C59.3785 23.2468 57.9077 23.6002 55.9754 23.6002H48.3184V8.00293H55.5517C57.3566 8.00293 58.7413 8.35288 59.7091 9.05628C60.6736 9.75968 61.1593 11.0055 61.1593 12.2093C61.1593 12.9407 60.987 13.5916 60.6426 14.1655C60.2981 14.7395 59.8125 15.1909 59.1925 15.5199C60.0364 15.7893 60.7011 16.2443 61.1799 16.8847ZM51.7249 10.918V14.463H55.1315C55.9788 14.463 56.6195 14.3125 57.0569 14.0116C57.4944 13.7106 57.7114 13.2662 57.7114 12.6783C57.7114 12.0903 57.4909 11.6494 57.0569 11.3555C56.6195 11.0615 55.9788 10.9145 55.1315 10.9145H51.7249V10.918ZM57.763 20.2687C58.2211 19.9677 58.4519 19.5023 58.4519 18.8724C58.4519 17.6266 57.5426 17.0036 55.7239 17.0036H51.7249V20.7201H55.7239C56.6229 20.7201 57.3049 20.5696 57.763 20.2687Z" fill="#1D2F43" />
            <path d="M64.4985 8H67.9258V20.5037H75.352V23.5973H64.4985V8Z" fill="#1D2F43" />
            <path d="M89.5799 20.1153H82.7048L81.4855 23.5973H77.9722L84.4787 8H87.8646L94.5778 23.5973H90.9818L89.5799 20.1153ZM88.4984 17.4696L86.1493 11.7095L83.8002 17.4696H88.4984Z" fill="#1D2F43" />
            <path d="M98.7348 22.5961C97.5154 21.9312 96.5544 21.0038 95.8586 19.8245C95.1594 18.6416 94.8115 17.3048 94.8115 15.8141C94.8115 14.3233 95.1594 12.9865 95.8586 11.8071C96.5579 10.6243 97.5154 9.70043 98.7348 9.03552C99.9541 8.37062 101.325 8.03467 102.851 8.03467C104.136 8.03467 105.293 8.26564 106.33 8.72407C107.367 9.1825 108.238 9.84041 108.941 10.7013L106.74 12.766C105.737 11.5902 104.497 11.0022 103.016 11.0022C102.1 11.0022 101.28 11.2052 100.564 11.6147C99.8439 12.0241 99.2824 12.5945 98.8829 13.3224C98.4799 14.0538 98.2801 14.8867 98.2801 15.8141C98.2801 16.7449 98.4799 17.5778 98.8829 18.3057C99.2859 19.0371 99.8439 19.6075 100.564 20.0134C101.284 20.4229 102.1 20.6259 103.016 20.6259C104.497 20.6259 105.737 20.0309 106.74 18.8411L108.941 20.9058C108.235 21.7807 107.36 22.4456 106.316 22.904C105.272 23.3625 104.108 23.5899 102.827 23.5899C101.322 23.5969 99.9575 23.2645 98.7348 22.5961Z" fill="#1D2F43" />
            <path d="M116.953 17.4277L114.966 19.5343V23.5973H111.559V8H114.966V15.3245L121.524 8H125.333L119.199 14.999L125.337 23.5973H121.338L116.953 17.4277Z" fill="#1D2F43" />
            <path d="M57.7527 48.9049L54.8973 44.457H54.7285H51.7456V48.9049H48.3184V33.3076H54.7285C56.0408 33.3076 57.1775 33.5281 58.1454 33.9725C59.1098 34.417 59.8538 35.0469 60.3774 35.8658C60.8975 36.6811 61.1593 37.9445 61.1593 39.0608C61.1593 40.1771 60.894 41.143 60.367 41.9514C59.8366 42.7633 59.0857 43.3827 58.1144 43.8096L61.4348 48.9049H57.7527ZM56.8847 37.1186C56.3474 36.6671 55.5655 36.4397 54.5356 36.4397H51.7421V41.6854H54.5356C55.5655 41.6854 56.3474 41.458 56.8847 40.996C57.422 40.5376 57.6873 39.8937 57.6873 39.0608C57.6907 38.2174 57.422 37.57 56.8847 37.1186Z" fill="#0C80E6" />
            <path d="M67.9825 47.8976C66.7494 47.2257 65.7815 46.2948 65.0857 45.1155C64.3865 43.9327 64.0386 42.6029 64.0386 41.1296C64.0386 39.6563 64.3865 38.3265 65.0857 37.1436C65.7849 35.9608 66.7494 35.0334 67.9825 34.358C69.2156 33.6861 70.6037 33.3467 72.1399 33.3467C73.6761 33.3467 75.0608 33.6826 76.287 34.358C77.5133 35.0334 78.4812 35.9608 79.1838 37.1436C79.8899 38.3265 80.2413 39.6563 80.2413 41.1296C80.2413 42.6064 79.8899 43.9362 79.1838 45.1155C78.4777 46.2983 77.5133 47.2257 76.287 47.8976C75.0608 48.573 73.6761 48.909 72.1399 48.909C70.6037 48.9055 69.2156 48.5695 67.9825 47.8976ZM74.5097 45.329C75.2158 44.9195 75.7669 44.3526 76.1699 43.6212C76.5729 42.8898 76.7727 42.0604 76.7727 41.1296C76.7727 40.1987 76.5729 39.3658 76.1699 38.6379C75.7669 37.9065 75.2158 37.3396 74.5097 36.9302C73.8036 36.5207 73.0148 36.3178 72.1399 36.3178C71.265 36.3178 70.4763 36.5207 69.7701 36.9302C69.064 37.3396 68.5129 37.91 68.1099 38.6379C67.7069 39.3693 67.5071 40.2022 67.5071 41.1296C67.5071 42.0604 67.7069 42.8933 68.1099 43.6212C68.5129 44.3526 69.064 44.923 69.7701 45.329C70.4763 45.7384 71.265 45.9414 72.1399 45.9414C73.0148 45.9414 73.8036 45.7384 74.5097 45.329Z" fill="#0C80E6" />
            <path d="M86.7925 47.8976C85.5594 47.2257 84.5915 46.2948 83.8957 45.1155C83.1965 43.9327 82.8486 42.6029 82.8486 41.1296C82.8486 39.6563 83.1965 38.3265 83.8957 37.1436C84.595 35.9608 85.5594 35.0334 86.7925 34.358C88.0256 33.6861 89.4137 33.3467 90.95 33.3467C92.4862 33.3467 93.8708 33.6826 95.0971 34.358C96.3233 35.0334 97.2912 35.9608 97.9938 37.1436C98.6999 38.3265 99.0513 39.6563 99.0513 41.1296C99.0513 42.6064 98.6999 43.9362 97.9938 45.1155C97.2877 46.2983 96.3233 47.2257 95.0971 47.8976C93.8708 48.573 92.4862 48.909 90.95 48.909C89.4137 48.9055 88.0291 48.5695 86.7925 47.8976ZM93.3197 45.329C94.0258 44.9195 94.577 44.3526 94.9799 43.6212C95.3829 42.8898 95.5827 42.0604 95.5827 41.1296C95.5827 40.1987 95.3829 39.3658 94.9799 38.6379C94.577 37.9065 94.0258 37.3396 93.3197 36.9302C92.6136 36.5207 91.8248 36.3178 90.95 36.3178C90.0751 36.3178 89.2863 36.5207 88.5802 36.9302C87.8741 37.3396 87.323 37.91 86.92 38.6379C86.517 39.3693 86.3172 40.2022 86.3172 41.1296C86.3172 42.0604 86.517 42.8933 86.92 43.6212C87.323 44.3491 87.8741 44.923 88.5802 45.329C89.2863 45.7384 90.0751 45.9414 90.95 45.9414C91.8248 45.9414 92.6136 45.7384 93.3197 45.329Z" fill="#0C80E6" />
            <path d="M107.07 42.7387L105.083 44.8454V48.9083H101.676V33.311H105.083V40.6355L111.641 33.311H115.451L109.316 40.31L115.812 48.9083H111.813L107.07 42.7387Z" fill="#0C80E6" />
        </svg>
    )
}
