import React from 'react'
import { makeStyles, Typography, Grid, Hidden } from '@material-ui/core'
import LinkComponent from '../GatsbyComponents/LinkComponent'
import ImageComponent from '../GeneralComponents/ImageComponent'
import EmailIcon from '@material-ui/icons/Email'
import LocationIcon from '@material-ui/icons/LocationOn'
import generalCss from '../../styles/generalCss'

const useStyles = makeStyles(theme => ({
    mainRoot: {
        backgroundColor: '#252525',
        backgroundImage: "url(/texture.png)",
        display: 'flex',
        justifyContent: 'center'
    },
    rootInterMediate: {
        maxWidth: generalCss.appMaxWidth,
    },
    root: {
        padding: '20px',
        [theme.breakpoints.up('md')]: {
            padding: '50px 2rem',
        }
    },
    disclaimer: {
        backgroundColor: '#3d3d3f',
        backgroundImage: "url(/texture.png)",
        padding: '20px',
        [theme.breakpoints.down("sm")]: {
            padding: "10px"
        }
    },
    disclaimerText: {
        color: 'white',
        fontWeight: "600",
        textAlign: 'center'
    },
    links: {
        color: 'white',
        fontWeight: '500',
        fontSize: "12px",
        textDecoration: 'none',
        [theme.breakpoints.up('md')]: {
            fontSize: '14px'
        }
    },
    socialContainer: {
        padding: '5px',
        width: "1.5rem",
        height: '1.5rem',
        borderRadius: "50%",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center'
    },
}))

export default function FooterList({
    disclaimer = '', appVersionData = { siteVersion: '', siteEnv: 'PRODUCTION' }, footerColumnList = [],
    footerRowList = [], data
}) {
    const classes = useStyles()
    const contactEmail = data.contactEmail ? data.contactEmail : ''
    const address = data.address ? data.address : ''
    const googleEmbedURL = data.googleEmbedURL ? data.googleEmbedURL : ''
    return (
        <div>
            <div className={classes.mainRoot}>
                <div className={classes.rootInterMediate}>
                    <div className={classes.root}>
                        {
                            (!!contactEmail || !!address) &&
                            <Hidden mdUp implementation='css'>
                                <Grid container spacing={2} style={{ paddingBottom: "20px" }}>
                                    {
                                        !!contactEmail &&
                                        <Grid item xs={12}>
                                            <a href={`mailto:${contactEmail}`} className={classes.links}>
                                                <Grid container spacing={1} alignItems='center' style={{ flexWrap: 'nowrap' }}>
                                                    <Grid item style={{ display: 'flex' }}><EmailIcon style={{ fontSize: '1.5rem' }} color='primary' /></Grid>
                                                    <Grid item><Typography variant='body2' style={{ color: 'white' }}>{contactEmail}</Typography></Grid>
                                                </Grid>
                                            </a>
                                        </Grid>
                                    }
                                    {
                                        !!address &&
                                        <Grid item xs={12}>
                                            <a href={googleEmbedURL} className={classes.links} target='_blank'>
                                                <Grid container alignItems='center' spacing={1} style={{ flexWrap: 'nowrap' }}>
                                                    <Grid item style={{ display: 'flex' }}><LocationIcon style={{ fontSize: '1.5rem' }} color='primary' /></Grid>
                                                    <Grid item><Typography variant='body2' style={{ color: 'white' }} style={{ whiteSpace: 'pre-line' }}>{address}</Typography></Grid>
                                                </Grid>
                                            </a>

                                        </Grid>
                                    }
                                </Grid>
                            </Hidden>
                        }

                        <Grid container alignItems='stretch' spacing={5}>

                            {
                                footerColumnList.map(item => {
                                    if (item.id === 'social') {
                                        return <Grid key={item.id} item xs={item.xsWidth} sm={item.smWidth} md={item.mdWidth}>
                                            <Grid container style={{ height: '100%', flexWrap: 'nowrap' }} justifyContent="space-between" alignItems='flex-end'>
                                                {
                                                    item.list.map(listItem => {
                                                        return <Grid item key={listItem.id} style={{ display: 'flex' }}>
                                                            <a href={listItem.slug} rel='nofollow' target="_blank">
                                                                <div className={classes.socialContainer} style={{ backgroundColor: listItem.bg }}>
                                                                    {listItem.image}
                                                                </div>
                                                            </a>
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                    return <Grid key={item.id} item xs={item.xsWidth} sm={item.xsWidth} md={item.smWidth}>
                                        <Grid container spacing={2}>
                                            {
                                                item.list.map(listItem => {
                                                    return <Grid item key={listItem.id} xs={12} >
                                                        <LinkComponent className={classes.links} appendPath={false} path={listItem.slug}>
                                                            {listItem.title}
                                                        </LinkComponent>
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                })
                            }
                            {
                                footerRowList.length !== 0 &&
                                <Grid item xs={12}>
                                    <Grid container alignItems='center' spacing={3}>
                                        {
                                            footerRowList.map(item => {
                                                return <Grid item key={item.id} sm={4} md={2}>
                                                    <LinkComponent className={classes.links} path={item.slug}>
                                                        {item.title}
                                                    </LinkComponent>
                                                </Grid>
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </div>

            </div>
            <div className={classes.disclaimer}>
                <Grid container direction='column' alignItems='center'>
                    <Grid item>
                        <Typography variant='body2' className={classes.disclaimerText}>{disclaimer}</Typography>
                    </Grid>
                    {
                        appVersionData.siteEnv === 'STAGING' &&
                        <Grid item>
                            <Typography variant='body2' className={classes.disclaimerText}>{appVersionData.siteVersion}</Typography>
                        </Grid>
                    }

                </Grid>
            </div>
        </div>


    )
}
