import React from 'react'
import { makeStyles, Grid, Hidden, Typography } from '@material-ui/core'
import ImageComponent from '../GeneralComponents/ImageComponent'
import EmailIcon from '@material-ui/icons/Email'
import CallIcon from '@material-ui/icons/Call'
import LocationIcon from '@material-ui/icons/LocationOn'
import generalCss from '../../styles/generalCss'
import Logo from '../SVGs/Logo'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        position: 'relative',
        overflow: "hidden",
        '&::after': {
            content: "''",
            position: 'absolute',
            width: "30%",
            height: '100%',
            right: '-15%',
            top: 0,
            backgroundColor: '#252525',
            backgroundImage: "url(/texture.png)",
            transform: 'skewX(-30deg)'
        }
    },
    blackRoot: {
        backgroundColor: '#252525',
        backgroundImage: "url(/texture.png)",
        position: 'relative',
        display: 'flex', justifyContent: 'center',
        '&::after': {
            content: "''",
            position: 'absolute',
            width: "50%",
            height: '100%',
            left: 0,
            top: 0,
            backgroundColor: 'white',
            zIndex: 0
        }
    },
    blackRootIntermediate: {
        width: '100%', margin: "auto",
        maxWidth: generalCss.appMaxWidth,
        zIndex: 1
    },
    container: {
        width: "85%",
        padding: '5px 20px',
        [theme.breakpoints.up('sm')]: {
            padding: '15px 2rem',
        }
    },
    links: {
        textDecoration: 'none'
    },
    image: {
        [theme.breakpoints.down("xs")]: {
            maxWidth: "90px"
        }
    },
    textContainer: {
        display: 'flex',
        justifyContent: "flex-start",
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-end'
        }
    },
    divider: {
        width: "0px",
        borderLeft: "1px solid #c4c4c4",
        minHeight: "40px"
    }
}))

function LocationComponent({ address }) {
    return <Grid container alignItems='center' spacing={1} style={{ flexWrap: 'nowrap' }}>
        <Grid item style={{ display: 'flex' }}><LocationIcon style={{ fontSize: '2rem' }} color='primary' /></Grid>
        <Grid item><Typography variant='body2' color='textPrimary' style={{ whiteSpace: 'pre-line' }}>{address}</Typography></Grid>
    </Grid>
}

export default function FooterHeader({ data = [] }) {
    const classes = useStyles()
    const contactEmail = data.contactEmail ? data.contactEmail : ''
    const contactPhone1 = data.contactPhone1 ? data.contactPhone1 : ''
    const contactPhone2 = data.contactPhone2 ? data.contactPhone2 : ''
    const address = data.address ? data.address : ''
    const googleEmbedURL = data.googleEmbedURL ? data.googleEmbedURL : ''
    return (
        <div className={classes.blackRoot}>
            <div className={classes.blackRootIntermediate}>
                <div className={classes.root}>
                    <div className={classes.container}>
                        <Grid container style={{ flexWrap: 'nowrap' }} alignItems='center'>
                            <Grid item>
                                <a href={'/'}>
                                    <Logo />
                                </a>
                            </Grid>
                            <Grid item className={classes.textContainer} style={{ flex: 1 }}>
                                <Grid container style={{ flexWrap: 'nowrap' }} justifyContent='space-evenly' alignItems='center'>
                                    {
                                        !!contactEmail &&
                                        <Hidden smDown implementation='css'>
                                            <Grid item>
                                                <a href={`mailto:${contactEmail}`} className={classes.links}>
                                                    <Grid container spacing={1} alignItems='center' style={{ flexWrap: 'nowrap' }}>
                                                        <Grid item style={{ display: 'flex' }}><EmailIcon style={{ fontSize: '2rem' }} color='primary' /></Grid>
                                                        <Grid item><Typography variant='body2' color='textPrimary'>{contactEmail}</Typography></Grid>
                                                    </Grid>
                                                </a>
                                            </Grid>

                                        </Hidden>
                                    }
                                    {
                                        !!contactEmail && (!!contactPhone1 || !!contactPhone2) &&
                                        <Hidden smDown implementation='css'>
                                            <Grid item>
                                                <div className={classes.divider}></div>
                                            </Grid>
                                        </Hidden>
                                    }
                                    {
                                        (!!contactPhone1 || !!contactPhone2) &&
                                        <Grid item >
                                            <Grid container spacing={1} alignItems='center' style={{ flexWrap: 'nowrap' }} >
                                                <Grid item style={{ display: 'flex' }}><CallIcon style={{ fontSize: '2rem' }} color='primary' /></Grid>
                                                <Grid item>
                                                    <Grid container direction='column' spacing={1}>
                                                        {
                                                            !!contactPhone1 &&
                                                            <Grid item >
                                                                <a href={`tel:${contactPhone1}`} className={classes.links}>
                                                                    <Typography variant='body2' color='textPrimary'>{contactPhone1}</Typography>
                                                                </a>
                                                            </Grid>
                                                        }
                                                        {
                                                            !!contactPhone2 &&
                                                            <Grid item >
                                                                <a href={`tel:${contactPhone2}`} className={classes.links}>
                                                                    <Typography variant='body2' color='textPrimary'>{contactPhone2}</Typography>
                                                                </a>
                                                            </Grid>
                                                        }

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        (!!contactPhone1 || !!contactPhone2) && !!address &&
                                        <Hidden smDown implementation='css'>
                                            <Grid item>
                                                <div className={classes.divider}></div>
                                            </Grid>
                                        </Hidden>
                                    }
                                    {
                                        !!address &&
                                        <Hidden smDown implementation='css'>
                                            <Grid item>
                                                {
                                                    !!googleEmbedURL ?
                                                        <a href={googleEmbedURL} className={classes.links} target='_blank'>
                                                            <LocationComponent address={address} />
                                                        </a> :
                                                        <LocationComponent address={address} />
                                                }
                                            </Grid>
                                        </Hidden>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                    </div>
                </div>
            </div>
        </div>

    )
}
